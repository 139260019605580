:root {
  --swiper-pagination-bullet-width: 32px;
  --swiper-pagination-bullet-size: 32px;
  --swiper-pagination-bullet-height: 2px;
  --swiper-pagination-color: var(--general-orange);
  --swiper-pagination-background: var(--general-white);
  --swiper-pagination-bullet-inactive-color: var(--general-ultra-light);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-border-radius: 0;
}