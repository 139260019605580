ion-checkbox {
  &.general {
    width: 16px;
    min-width: 16px;
    height: 16px;
    --checkbox-background-checked: var(--general-black);
    --border-color-checked: var(--general-black);
    --border-width: 1px;
    --border-color: var(--general-light-grey);
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ion-checkbox {
    --border-radius: 0;
    --checkbox-background-checked: var(--general-black);
    --border-color-checked: none;
    --checkmark-color: var(--general-white);
  }

  .label {
    margin-left: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    span {
      color: var(--general-orange);
    }
  }
}