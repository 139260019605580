.container {
  padding: 0 16px;
}

ion-toolbar {
  --border-width: 0 !important;
  --padding-bottom: 1px;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 1px;
}

ion-icon {
  font-size: 24px;
}

.booking_segment {
  ion-segment-button.md {
    --indicator-height: 0 !important;
  }
}