ion-input,
ion-select,
ion-textarea {
  &.general {
    height: 48px;
    // padding: 12px 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    --color: var(--general-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 8px;
    --border-radius: 8px;
    --border-style: solid;
    --boder-width: 1px;
    --border-color: var(--general-ultra-light);
    border: 1px solid var(--general-ultra-light);

    &.with_icon {
      --padding-start: 48px;
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    &.selected {
      --border-color: var(--general-orange);
      border: 1px solid var(--general-orange);
    }

    &.height_auto {
      height: unset;
    }
  }
}

.input_box {
  position: relative;

  ion-icon.left_icon {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    z-index: 99;
  }

  .error {
    margin-top: 8px;
    color: var(--general-red);
  }
}

ul {
  margin-block-start: 2px;
  padding-inline-start: 24px;
}

ion-select::part(icon) {
  position: absolute;
  right: 6px;
  width: 24px;
  height: 24px;
}

ion-radio {
  --color-checked: var(--general-orange) !important;
}