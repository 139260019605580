// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


@font-face {
  font-family: "RedHatDisplay";
  src: url('/assets/fonts/RedHatDisplay-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "RedHatDisplay";
  src: url('/assets/fonts/RedHatDisplay-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
}

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "RedHatDisplay";

  --general-white: #FFFFFF;
  --general-offwhite: #F5F5F5;
  --general-ultra-light: #E4E4E4;
  --general-light-off-grey: #E5E5E5;
  --general-light-grey: #B4B4B4;
  --general-grey: #7F8694;
  --general-black: #161616;
  --general-beige: #F5D4A5;
  --general-orange: #EBA747;
  --general-red: #F65656;

  --warning: #EBA747;

  /** dp-header component **/
  --light: #FFFFFF;
  --primary: #161616;

  /** Toast **/
  --bg-info: #3F83F8;
  --bg-success: #0E9F6E;
  --bg-warning: #FF5A1F;
  --bg-danger: #F05252;
  --danger: #F05252;
  --button-info: #A4CAFE;
  --button-success: #84E1BC;
  --button-warning: #FDBA8C;
  --button-danger: #F8B4B4;

  --blue-100: #E1EFFE;
  --blue-800: #1E429F;
  --yellow-100: #FDF6B2;
  --yellow-900: #6B5326;
  --red-100: #FDE8E8;
  --red-800: red;
  --green-100: #E5F2E5;
  --green-800: green;
  --purple-100: #EDEBFE;
  --pink-100: #FCE8F3;
  --pink-800: #99154B;
  --indigo-100: #E5EDFF;
  --indigo-800: #42389D;
  --gray-50: #F9FAFB;
  --gray-100: #F3F4F6;
  --gray-200: #E5E7EB;
  --gray-300: #D1D5DB;

  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --font-size-12: 12px;

  /** Default values header-bar **/
  --default-header-text-size: 18px;
  --default-header-icon-size: 24px;
  --default-header-padding: 8px;

  /** Default values alert component **/
  --alert-padding: 0 16px 16px 16px;
  --alert-border_radius: 8px;
  --alert-bg-ok_button: var(--ion-color-primary);
  --alert-text-ok_button: var(--ion-color-primary-contrast);
  --alert-bg-cancel_button: var(--ion-color-secondary);
  --alert-text-cancel_button: var(--ion-color-secondary-contrast);

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}