ion-loading {
  --max-width: 100% !important;
  align-items: baseline !important;

  .loading-wrapper {
    width: 100% !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-radius: 0;
    position: relative;


    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 4px;
      width: 30vw;
      background: var(--general-orange);
      /* Цвет линии */
      animation: moveRight 4s infinite, resizeWidth 1.5s infinite;
    }


    .loading-content,
    .loading-spinner {
      display: none;
    }
  }
}


@keyframes moveRight {
  0% {
    left: -30vw;
  }

  100% {
    left: 100%;
  }
}

@keyframes resizeWidth {

  0%,
  100% {
    width: 25vw;
  }

  50% {
    width: 35vw;
  }
}