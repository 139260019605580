.heading-l {
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

.heading-m {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
}

.body-l {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.body-m-regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.body-m-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.body-s-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.label-s-regular {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.label-s-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}