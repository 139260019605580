/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Digitup package Styles */
@import "node_modules/digitup-lib/dist/digitup-lib/assets/styles.scss";

/* Swiper package Styles */
@import 'swiper/css/bundle';

@import "theme/alert.scss";
@import "theme/fonts.scss";
@import "theme/general.scss";
@import "theme/buttons.scss";
@import "theme/inputs.scss";
@import "theme/toast.scss";
@import "theme/datetime.scss";
@import "theme/toggle.scss";
@import "theme/modals.scss";
@import "theme/checkbox.scss";
@import "theme/swiper.scss";
@import "theme/loading.scss";

.ion-safe-top {
  padding-top: var(--ion-safe-area-top, 0) !important;
}

.ion-safe-bottom {
  padding-bottom: var(--ion-safe-area-bottom, 0) !important;
}

ion-header {
  ion-toolbar {
    --background: var(--general-white);
    --border-style: none;
    --padding-start: 0;
    --padding-end: 0;

    .head-title {
      background-color: var(--general-white);
      color: var(--general-black);
      height: 52px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: var(--font-size-28);
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    .header-container {
      padding: 0 16px;
    }
  }
}

ion-header {
  &.modal-header {
    ion-toolbar {
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --padding-end: 0;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .title-container {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;

        .title {
          color: var(--general-black);
          font-size: var(--font-size-24);
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }

        .close {
          margin-left: 4px;
          padding-top: 4px;

          ion-icon {
            color: var(--general-black);
            font-size: 24px;
          }
        }
      }
    }
  }

  &.buttons-header {
    ion-toolbar {
      .content {
        &.horizontal {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .title-container {
            width: 40%;
            order: 2;
            justify-content: flex-end;
          }

          .button-container {
            width: 60%;
            order: 1;
            justify-content: unset;
            padding: 8px 16px;

            ion-button {
              &:first-child {
                margin-right: 8px;
              }
            }
          }
        }

        .title-container {
          padding: 0 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .head-title {
            padding: 0;
          }

          .filter {
            display: flex;
            align-items: center;

            ion-button {
              height: 48px;
              --padding-start: 12px;
              --padding-end: 12px;
              font-size: var(--font-size-16);
              font-style: normal;
              font-weight: 400;
              line-height: 24px;

              ion-icon {
                margin-left: 8px;
                margin-right: 0;
              }
            }
          }
        }

        .button-container {
          padding: 16px;
          display: flex;
          justify-content: space-between;

          ion-button {
            width: 165px;
            --border-radius: 100px;
            --color: var(--general-grey);

            &.active {
              --color: var(--general-black);
              --background: var(--general-beige);
              --border-width: 0;
              font-size: var(--font-size-16);
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

ion-footer {
  &.general-footer {
    ion-toolbar {
      --border-style: none !important;
      --border-width: 0 !important;
      --background: var(--general-white);
      padding: 0;

      .content {
        &.horizontal {
          padding: 16px !important;
        }

        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        ion-button {
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }
}

.hidden {
  display: none;
}

a {
  color: var(--general-orange);
}

.one_line_country_code {
  margin-top: 8px;
  display: flex;
  gap: 8px;

  .country_code {
    background-color: var(--general-white);
    border-radius: 8px;
    padding: 0 8px;
    border: 1px solid var(--general-ultra-light);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
  }

  .phone_number {
    width: 100%;

    ion-input {
      margin-top: 0 !important;
    }
  }
}