ion-modal {

  &.journey_extras {
    --height: 70vh;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.is_info {
    --height: 43vh;
    --min-height: 290px;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.my_booking {
    --height: auto;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;

    // &.is_cancel {
    //   --height: auto;
    // }
  }

  &.cancel_account {
    --height: 52vh;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.generic_small {
    --height: 43vh;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.generic_payment_method {
    --height: 236px;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.preparing_trip {
    --height: 24vh;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.radio_modal {
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.action_modal {
    --height: auto;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.chat_detail {
    --height: 100%;
    --max-height: 480px;
    --border-radius: 10px 10px 0 0;
    align-items: flex-end;
    --backdrop-opacity: 0.4 !important;
  }

  &.terms {
    --width: 90%;
    --height: 390px;
    --border-radius: 8px;
    --max-width: 360px;
  }
}


@media(min-height: 567px) {
  ion-modal {

    &.journey_extras {
      --height: 77vh !important;
    }

    &.journey_modal {
      // --height: 800px;
      --max-height: 100%;
    }
  }
}

@media(min-width: 800px) {
  ion-modal {
    &.journey_modal {
      --height: 800px;
      --max-height: 100%;
    }
  }
}