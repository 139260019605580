ion-alert {

  &.general_alert {
    --width: 100% !important;
    --max-width: 660px !important;
    padding: 24px;

    .alert-button-group {
      flex-direction: initial !important;

      button {
        margin-top: 0 !important;
        color: black;
      }
    }
  }

  &.delete_alert {
    .alert-button-group {
      .ok_button {
        background-color: var(--general-red);
      }

      .cancel_button {
        background-color: var(--general-grey);
      }
    }
  }

  &.success {
    .alert-button-group {
      .cancel_button {
        background-color: var(--bg-danger);
        color: var(--light) !important;
      }

      .ok_button {
        background-color: var(--bg-success);
        color: var(--light) !important;
      }
    }
  }
}