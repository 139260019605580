// ion-toggle {
//   &.general {
//     --track-background-checked: var(--general-black);
//   }
// }

ion-toggle {
  --handle-max-height: 24px;
  --handle-height: 24px;
  --handle-width: 24px;
  --handle-background: var(--general-white);
  --handle-background-checked: var(--general-white);
  --track-background: var(--general-ultra-light);
  --track-background-checked: var(--general-black);
  --handle-box-shadow: 0;

  &::part(track) {
    width: 56px;
    height: 28px;
  }
}