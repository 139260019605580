ion-button {
  --border-radius: 8px;
  border-radius: 8px;
  margin: 0;

  &.general {
    --background: var(--general-beige);
    --color: var(--general-black);
    color: var(--general-black);
    height: 48px;

    ion-icon {
      --color: var(--general-black);
      color: var(--general-black);
    }
  }

  &.success {
    --background: var(--bg-success);
    --color: var(--general-white);
    color: var(--general-white);

    ion-icon {
      --color: var(--general-white);
      color: var(--general-white);
    }
  }

  &.dark {
    --background: var(--general-black);
    --color: var(--general-white);
    color: var(--general-white);
    height: 48px;

    ion-icon {
      --color: var(--general-white);
      color: var(--general-white);
    }
  }

  &.secondary {
    --background: transparent;
    --color: var(--general-black);
    color: var(--general-black);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--general-ultra-light);
    height: 48px;
  }

  &.cancel {
    --background: var(--general-red);
    --color: var(--general-white);
    color: var(--general-white);
    height: 48px;

    ion-icon {
      --color: var(--general-white);
      color: var(--general-white);
    }

    &.secondary {
      --background: transparent;
      --color: var(--general-red);
      color: var(--general-red);
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--general-ultra-light);
    }
  }

  ion-icon {
    margin-right: 8px;
  }

  &.big_one_2 {
    height: 80px;
    font-size: 32px;
    font-weight: bold;
    white-space: break-spaces;
  }

  &.big_one {
    height: 25vh;
    font-size: 48px;
    white-space: break-spaces;

    &.senior {
      height: 40vh;
      font-size: 72px;
      font-weight: 700;
    }
  }
}

.buttons_box {
  display: flex;
  gap: 8px;

  ion-button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}