.ion-color-rose {
  --ion-color-base: var(--general-orange);
  --ion-color-base-rgb: var(--general-orange);
  --ion-color-contrast: var(--general-white);
  --ion-color-contrast-rgb: var(--general-white);
  --ion-color-shade: var(--general-beige);
  --ion-color-tint: var(--general-beige);
}

ion-datetime-button {
  height: 48px;
  --color: var(--general-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid var(--general-ultra-light);
  background-color: var(--general-white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

ion-datetime-button::part(native) {
  background-color: transparent !important;
}